exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ausstattung-js": () => import("./../../../src/pages/ausstattung.js" /* webpackChunkName: "component---src-pages-ausstattung-js" */),
  "component---src-pages-bewertungen-js": () => import("./../../../src/pages/bewertungen.js" /* webpackChunkName: "component---src-pages-bewertungen-js" */),
  "component---src-pages-bilder-js": () => import("./../../../src/pages/bilder.js" /* webpackChunkName: "component---src-pages-bilder-js" */),
  "component---src-pages-buchung-js": () => import("./../../../src/pages/buchung.js" /* webpackChunkName: "component---src-pages-buchung-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-gaestebuch-js": () => import("./../../../src/pages/gaestebuch.js" /* webpackChunkName: "component---src-pages-gaestebuch-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */)
}

